.attached {
  box-shadow: var(--themeShadow2);
  background: var(--skinPrimaryLite);
  height: 40px;
}

.wrap {
  color: var(--themeTextColor);
}

.icon {
  color: var(--skinPrimary);
}
