.inverse {
  background: var(--themeCardInverse);
}

.active {
  box-shadow: inset 0 0 0 2px var(--skinPrimary);
}

.shadow1.active {
  box-shadow: inset 0 0 0 2px var(--skinPrimary), var(--themeShadow1);
}

.shadow2.active {
  box-shadow: inset 0 0 0 2px var(--skinPrimary), var(--themeShadow2);
}

.shadow3.active {
  box-shadow: inset 0 0 0 2px var(--skinPrimary), var(--themeShadow3);
}

.shadow4.active {
  box-shadow: inset 0 0 0 2px var(--skinPrimary), var(--themeShadow4);
}
