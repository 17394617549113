.animated {
  font: var(--themeText);
  transform: translateY(20px);
  line-height: 40px;
}

.withLeftIcon.animated {
  transform: translate(44px, 20px);
}

.withRightIcon.animated {
  right: 34px;
}

.animated.focused,
.animated.hasValue {
  transform: translate(0, 0);
  font: var(--themeTextSmall);
  line-height: 20px;
}

.large.animated {
  font: var(--themeH3);
  line-height: 40px;
}

.large.animated.focused,
.large.animated.hasValue {
  font: var(--themeH4);
}

.animated.error {
  color: var(--themeTextColorLabel);
}
