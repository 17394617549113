.content {
  --popoverArrowSize: 10px;

  box-shadow: var(--themeShadow3);
  background: var(--themeCard);
}

.spacedOutside {
  margin: var(--popoverArrowSize);
}

.arrow {
  width: var(--popoverArrowSize);
  height: var(--popoverArrowSize);
  background: var(--themeCard);
  transform: rotate(45deg);
}

.rightStart .arrow,
.right .arrow,
.rightEnd .arrow {
  left: -4px;
  box-shadow: 4px -4px 0 4px var(--themeCard), -2px 2px 10px 0 rgba(0, 0, 0, 0.2), 4px -4px 0 4px var(--themeCard);
}

.leftStart .arrow,
.left .arrow,
.leftEnd .arrow {
  right: -4px;
  box-shadow: -4px 4px 0 4px var(--themeCard), 2px -2px 10px 0 rgba(0, 0, 0, 0.1), -4px 4px 0 4px var(--themeCard);
}

.topStart .arrow,
.top .arrow,
.topEnd .arrow {
  bottom: -4px;
  box-shadow: -4px -4px 0 4px var(--themeCard), 2px 2px 8px 0 rgba(0, 0, 0, 0.1), -4px -4px 0 4px var(--themeCard);
}

.bottomStart .arrow,
.bottom .arrow,
.bottomEnd .arrow {
  top: -4px;
  box-shadow: 4px 4px 0 4px var(--themeCard), -2px -2px 8px 0 rgba(0, 0, 0, 0.1), 4px 4px 0 4px var(--themeCard);
}

/* Move element outside by 20px of it's content size */
.leftStart.beyondBorders .content,
.left.beyondBorders .content,
.leftEnd.beyondBorders .content,
.rightStart.beyondBorders .content,
.right.beyondBorders .content,
.rightEnd.beyondBorders .content {
  margin-top: -20px;
  margin-bottom: -20px;
}

.topStart.beyondBorders .content,
.top.beyondBorders .content,
.topEnd.beyondBorders .content,
.bottomStart.beyondBorders .content,
.bottom.beyondBorders .content,
.bottomEnd.beyondBorders .content {
  margin-left: -20px;
  margin-right: -20px;
}

/* No shadow for error */
.error .arrow,
.error .content {
  box-shadow: none;
}
