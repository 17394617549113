.shareCircle {
  stroke: var(--skinPrimary);
}

.percentage {
  color: var(--skinPrimary);
}

.withOverlay.inverse::before {
  background: var(--themeCardInverse);
}

/* Color modifications */
.inverse .shareCircle {
  stroke: var(--skinPrimary);
}
