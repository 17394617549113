.active .icon::after {
  position: absolute;
  content: "";
  z-index: 101;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: spin 1s infinite easeInOutBack;
  border: 2px solid var(--themeDivider);
  border-top-color: var(--themeIconMuted);
  border-radius: 50%;
  will-change: transform;
}

.inverse .icon::after {
  border-color: var(--themeDividerInverse);
  border-top-color: var(--themeIconMutedInverse);
}

/* Loader animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
