.default {
  background: var(--themeTag);
  color: var(--themeTextColorInverse);
}

.primary {
  background: var(--skinPrimary);
  color: var(--themeTextColorInverse);
}

.primary.large {
  background: var(--skinPrimaryLite);
  color: var(--skinPrimary);
}

.accent {
  background: var(--skinAccent);
  color: var(--themeTextColorInverse);
}

.accent.large {
  background: var(--skinAccentLite);
  color: var(--skinAccent);
}

.success {
  background: var(--skinSuccess);
  color: var(--themeTextColorInverse);
}

.success.large {
  background: var(--skinSuccessLite);
  color: var(--skinSuccess);
}

.danger {
  background: var(--skinDanger);
  color: var(--themeTextColorInverse);
}

.danger.large {
  background: var(--skinDangerLite);
  color: var(--skinDanger);
}

.translucent {
  background: var(--themeTagTranslucent);
  color: var(--themeTextColorInverse);
}

.translucent.large {
  background: var(--themeButton);
  color: var(--themeTextColor);
}

.translucent.large .icon {
  color: var(--themeIcon);
}

/* Inverse colors for tags on active elements */
.inverse {
  background: var(--themeCard);
  color: var(--skinPrimary);
}

.translucent.inverse .icon {
  color: var(--themeTextColorInverse);
}

.translucent.inverse {
  background: var(--themeTagTranslucent);
  color: var(--themeTextColorInverse);
}
