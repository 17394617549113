.bordered {
  border-top: 1px solid var(--themeDivider);
  border-bottom: 1px solid var(--themeDivider);
}

.inverse.bordered {
  border-top: 1px solid var(--themeDividerInverse);
  border-bottom: 1px solid var(--themeDividerInverse);
}

.bordered:last-child {
  border-bottom: none;
}

.skipFirstTopBorder.bordered:first-child {
  border-top: none;
}

.content {
  color: var(--themeTextColorMuted);
}

.inverse .content {
  background: var(--themeCardInverse);
  color: var(--themeTextColorMutedInverse);
}
