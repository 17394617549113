.container {
  border-bottom: 1px solid var(--themeInputBorder);
}

.container::before {
  display: block;
  position: absolute;
  right: 50%;
  bottom: -2px; /* Need to hide border-bottom */
  left: 50%;
  height: 2px;
  content: "";
  background: var(--skinPrimary);
  transition: var(--themeTransition);
}

.focused .container::before,
.primary .container::before {
  right: 0;
  left: 0;
  background: var(--skinPrimary);
}

/* Input with full border */
.full > .container {
  border: none;
  border-radius: var(--themeRadius);
}

.full > .container::before {
  display: none;
}

.full.error > .container {
  box-shadow: 0 0 0 2px var(--skinDanger);
}

.full.error.focused > .container {
  box-shadow: 0 0 0 2px var(--skinPrimary);
}

/* Input, without borders */
.unbordered .container {
  border: none;
}

.unbordered .container::before {
  display: none;
}

.unbordered.error.focused .container,
.unbordered.error .container {
  box-shadow: none;
}

/* Error state */
.error .container::before {
  right: 0;
  left: 0;
  background: var(--skinDanger);
}

.error.focused .container::before {
  background: var(--skinPrimary);
}

/* stylelint-disable-next-line */
.inverse.error .container::before {
  background: var(--skinDanger);
}
