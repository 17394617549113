.tab {
  border-radius: var(--themeRadius);
  border-bottom: 2px solid transparent;
}

.inverse {
  color: var(--themeTextColorMutedInverse);
}

.tab.hoverable:not(.active):hover {
  background: var(--themeCardLight);
}

.active {
  color: var(--skinPrimary);
  border-bottom-color: var(--skinPrimary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.inverse.active {
  color: var(--themeTextColorInverse);
  border-bottom-color: var(--themeTextColorInverse);
}

.asIcon {
  color: var(--themeIcon);
}

.asIcon.inverse {
  color: var(--themeIconInverse);
}

.asIcon.active {
  color: var(--skinPrimary);
}
