.content {
  --tooltipArrowSize: 8px;

  background: var(--themeCardInverse);
  margin: var(--tooltipArrowSize);
}

.arrow {
  width: var(--tooltipArrowSize);
  height: var(--tooltipArrowSize);
  background: var(--themeCardInverse);
  transform: rotate(45deg);
}

.rightStart .arrow,
.right .arrow,
.rightEnd .arrow {
  left: -4px /* rtl:ignore */;
}

.leftStart .arrow,
.left .arrow,
.leftEnd .arrow {
  right: -4px /* rtl:ignore */;
}

.topStart .arrow,
.top .arrow,
.topEnd .arrow {
  bottom: -4px;
}

.bottomStart .arrow,
.bottom .arrow,
.bottomEnd .arrow {
  top: -4px;
}
