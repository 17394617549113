.button {
  border-radius: var(--themeRadius);
}

.round {
  border-radius: var(--themeRadiusLarge);
}

.shadow1 {
  box-shadow: var(--themeShadow1);
}

.flat,
.flatPay,
.shadow0 {
  box-shadow: none;
}

.flat .text,
.flatPay .text {
  text-decoration: underline;
}

.hoverable.flat:active .text,
.hoverable.flat:hover .text {
  text-decoration: none;
}

.flat.narrow .text {
  text-decoration: none;
}

/* Flat cover button */
.flatCover {
  color: var(--themeTextColor);
  background: var(--themeIconInverse);
  box-shadow: none;
}

.flatCover .icon {
  color: var(--themeIcon);
}

/* Inverse flat cover button */
.flatCover.inverse {
  color: var(--themeTextColorCoverInverse);
  background: var(--themeTagTranslucent);
}

.flatCover.inverse .icon {
  color: var(--themeIconInverse);
}

.flat.active .icon,
.flat.round.active .icon {
  color: var(--skinPrimary);
}

/* stylelint-disable */
.default.dangerAsActiveColor.active .icon,
.flat.dangerAsActiveColor.active .icon,
.flat.inverse.dangerAsActiveColor.active .icon,
.flat.round.dangerAsActiveColor.active .icon {
  color: var(--skinDanger);
}
/* stylelint-enable */
