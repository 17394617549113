/**
 * Size modifications
 * Font sizes should be set based on "iconSize.js"
 */
.smallest {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.small {
  width: 40px;
  height: 40px;
  font-size: 24px;
}

.normal {
  width: 60px;
  height: 60px;
  font-size: 24px;
}

.large {
  width: 80px;
  height: 80px;
  font-size: 36px;
}
