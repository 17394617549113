.accordion {
  border-bottom: 1px solid var(--themeDivider);
}

.accordion:first-child {
  border-top: 1px solid var(--themeDivider);
}

.unbordered,
.unbordered:first-child {
  border: none;
}

.withoutTopBorder,
.withoutTopBorder:first-child {
  border-top: none;
}

.action {
  height: 59px; /* remove 1px because of border */
}

.unbordered .action {
  height: 60px;
}
