.badge {
  width: 20px;
  height: 20px;
}

.badge::after {
  width: 10px;
  height: 10px;
  border: 2px solid var(--themeCard);
}
